.aside-logo{}
.aside-logo span{
    position: relative;
    z-index: 0;
    top: 4px;
}

.linkies{}
.linkie{
    background-color: #fff !important;
    border-radius: 6px;
    margin-bottom: 22px;
    padding: 16px 6px !important;
    display: flex;
    align-items: center;
    box-shadow:  0 0 10px 0 rgb(76 87 125 / 3%);
}
.linkie:hover{
    box-shadow:  0 0 10px 0 rgb(76 87 125 / 12%);
}
.linkie .symbol span,
.user-name span{
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #3f4254;
    background-color: #f5f8fa;
    border-radius: 6px;
    font-size: 18px;
}
.linkie .symbol span img{
    height: 25px;
}
.linkie:not(:hover) .buttons{
    opacity: 0;
}

.user-name{
    display: flex;
    align-items: center;
}
.user-name span{
    width: 36px;
    height: 36px;
    font-size: 15px;
    font-weight: 600;
}

.modal.add-new-linkie{}
.modal.add-new-linkie .lists label{
    margin-bottom: 12px;
    width: 100%;
    text-align: left;
}
.modal.add-new-linkie .lists .btn-check:checked + .btn.btn-primary,
.modal.add-new-linkie .lists .btn-check:checked + .btn.btn-light-primary {
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px 16px;
}
.modal.add-new-linkie .lists .btn-light-primary{
  border:2px solid !important;
}
.modal.add-new-linkie .lists .btn-check + .btn.btn-light-primary,
.modal.add-new-linkie .lists .btn-check:checked + .btn.btn-light-primary{
  border-color: #333333 !important;
}

hr.dashed {
	border-top: 2px dashed #8c8b8b;
    background-color: transparent;
}

/* starts, tabs css */
.c-tabs .nav-pills{
    display: inline-flex;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 60px !important;
    margin-bottom: 26px;
    padding: 6px;
  }
  .c-tabs .nav-link{
    padding: 6.5px;
    font-weight: 600;
    border-radius: 48px !important;
    min-width: 178px;
  }
  .c-tabs .nav-pills .nav-link.active{
    color: #fff;
  }
  @media (min-width: 1025px) {
    .c-tabs .nav-pills{
      position: fixed;
      background-color: #EEF0F8;
      top: 10.5px;
      z-index: 100;
      left: calc(50% - 149px);
    }
    .c-tabs .nav-link{
      padding: 6.5px 30px;
    }
  }
  /* end, tabs css */

#notification-container {
    position: fixed;
    top: 200px;
    left: 20%;
    right: 20%;
    z-index: 9991;
    display: none;
    pointer-events: none;
}

#notification-container #notification-content {
    position: relative;
    margin: 0 auto !important;
    width: 400px !important;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    color: white;
    font-size: 28px;
    line-height: 52px;
}

#notification-container #notification-content.error {
    background: rgba(255, 0, 0, 1) !important;
}

.custom-btn-font-size {
    font-size: 1rem;
}

.btn-outline-primary {
    border: 2px solid #333333 !important;
}