.pages {

  // manage-lists
  &.manage-lists {

    //linkies 
    .linkies {
      max-width: 100%;
      overflow: hidden;
      [data-react-beautiful-dnd-droppable]{
        margin: 0 -12px 12px -12px;
        [data-react-beautiful-dnd-draggable]{
          flex-grow: 1;
          max-width: 25%;
          min-width: 25%;
          padding: 0 12px;
          align-self: stretch;
          .list{
            height: 100%;
            .card-body{
              padding: 16px 48px 16px 16px;
            }
            .c-menu{
              position: absolute;
              top:12px;
              right:12px;
              .btn{
                height: 24px;
                width: 24px;
              }
            }
          }
        }
      }
    }

  }

}