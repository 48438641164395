.aside-menu {
  .buttons {
    padding: 0 26px 26px 26px;
  }
  .menu{
    .menu-item{
      .menu-link{
        &.active,
        &:hover{
          .menu-icon{
            .svg-icon{
              svg [fill]{
                &:not(.permanent):not(g){
                  fill:$green !important;
                }
              }
            } 
          }
          .menu-title{
            color:$green !important;
          }
        }
      }
    }
  }
}